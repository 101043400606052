<script setup lang="ts">
import Navigation from '@/components/custom/Navigation.vue';
import Footer from '@/components/custom/Footer.vue';

// useHead({
//   link: {
//     rel: 'canonical',
//     href: 'https://spotsurfer.com'
//   }
// })
useSeoMeta({
  title: 'Spotsurfer | Reserved Parking',
  ogTitle: 'Reserve Your Parking Spot in Vail & Beaver Creek',
  description: 'Skip the parking hassle! Stress-free parking is just a tap away.',
  ogDescription: 'Skip the parking hassle! Stress-free parking is just a tap away.',
  ogImage: '/_ipx/_/images/spotsurferLogo.png',
  ogUrl: 'https://spotsurfer.com',
  twitterCard: 'summary_large_image',
})
</script>

<template>
  <Navigation />
  <main>
    <slot />
  </main>
  <Footer />
</template>
